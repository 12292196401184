swiper-container {
    width: 100%;
    margin: 0 auto;
    height: 100%;
}

swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    padding: 2rem 0;
    transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    &.swiper-slide-active{
    }
}

swiper-slide:hover{
    transform: translateY(-20px);
}


