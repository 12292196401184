@font-face {
  font-family: 'Tommy';
  src: url('../public/fonts/MADE TOMMY Bold_PERSONAL USE.otf');
}

@font-face {
  font-family: 'Noir';
  src: url('../public/fonts/Film Noir Adventure.ttf');
}

@font-face {
  font-family: 'Treb';
  src: url('../public/fonts/trebucbd.ttf');
}


#root{
  --pp-dark: #3D2B60;
  --pp-200: #7951B7;
  --pp-100: #624295;
  --pp-300: #8561BD;
  --pp-500: #662D91;
  --pp-600: #D9CBF2;
  --pp-700: #E0D7EE;
  --pp-800: #C3A4FF;
  --pp-light: #C8B8E2;

  --primary-col: #001824;
  --text-unFocused: #919DA5;
  --text-focused: #F1F2F2;
  --accent-col: #FF1D28;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

