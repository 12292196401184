.swiper-container {
    width: 100%;
    margin: 0 auto;
    height: 100%;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
}